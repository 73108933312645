<template>
    <div>
        <question-text
            :question="question"
            :errors="errors"
            @change="updateQuestion"
            @focus="clearTextInput"
            style="width: 100%;"
        ></question-text>
        <v-checkbox
            v-model="randomizeRows"
            label="Randomize rows"
            hide-details="auto"
        ></v-checkbox>
        <v-checkbox
            v-model="matrixTopErrorMessage"
            label="Show validation message"
            hide-details="auto"
        ></v-checkbox>

        <tooltip>
                <template #activator="{ on, attrs }">
                    <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        prepend-inner-icon="mdi-table-row-plus-before"
                        class="mt-3 variable-input"
                        hide-details="auto"
                        v-model="rowSourceVariable"
                        placeholder="Row source variable_name"
                        outlined
                        dense
                        :rules="[rules.variableRule]"
                        @change="updateQuestion"
                    ></v-text-field>
                </template>
                <span class="text-caption white--text">
                    Variable that will be used to populate the rows dynamically. 
                    <a 
                    class="grey--text text--lighten-1" 
                    href="https://docs.voiceform.com/docs/matrix#adding-rows-dynamically" 
                    target="_blank">
                    Learn More
                    </a>
                </span>
        </tooltip>
    </div>
</template>

<script>
import QuestionText from "../QuestionText.vue"
import Tooltip from "@/components/Tooltip.vue";

export default {
    name: "MatrixType",
    props: ["question", "errors"],
    components: { QuestionText, Tooltip },
    data(){
        return {
            rules: {
                variableRule: v => {
                    if (!v) return true;
                    return /^[a-zA-Z_]\w*$/.test(v) || 'Name must start with a letter and contain only alphanumeric characters and underscores'},
            },
            variable: this.question.variable_name || ''
        }
    },
    inject: [
        "deleteChoiceFromQuestion",
        "addChoiceToQuestion",
        "updateQuestion",
    ],
    computed: {
        randomizeRows: {
            get() {
                return this.question.properties && this.question.properties.randomize_rows || false;
            },
            set(value) {
                this.question.properties = {...this.question.properties, randomize_rows: !!value };
                this.updateQuestion();
            }
        },
        matrixTopErrorMessage: {
            get() {
                return this.question.properties && this.question.properties.matrix_validation_message === false? false : true;
            },
            set(value) {
                this.question.properties = {...this.question.properties, matrix_validation_message: value };
                this.updateQuestion();
            }
        },
        rowSourceVariable: {
            get() {
                return this.question.properties && this.question.properties.row_source_variable || '';
            },
            set(value) {
                this.question.properties = {...this.question.properties, row_source_variable: value };
            }
        }
    },
    methods: {
        clearTextInput() {
            if(this.question.text === "Type your question here") {
                this.question.text = "";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.variable-input{
    font-family: 'Roboto Mono', monospace;
}
</style>